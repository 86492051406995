(function($) {
    $(document).ready(function () {
        var $accessibilityOptions = $(".accessibility-options");
        var $accessibilityToggle = $(".accessibility-toggle");
        var $accessibilityClose = $(".accessibility-options").find(".close");

        $accessibilityToggle.on("click", function () {
            $("body").toggleClass("accessibility-open");
            $accessibilityOptions.toggleClass("open");
        });

        $accessibilityClose.on("click", function () {
            $accessibilityOptions.removeClass("open");
            $("body").removeClass("accessibility-open");
        });

        $(".accessibility .btn-statement").on('blur', function(){
            $accessibilityOptions.removeClass('open');
            $("body").removeClass('accessibility-open');
        });
    });

    $(document).on('keydown', function(event) {
        var $accessibilityOptions = $(".accessibility-options");
        if (event.key == "Escape") {
            $accessibilityOptions.removeClass("open");
        }
    });

    $(document).ready(function () {
        if (localStorage.getItem("accessibilitySettings") !== null) {
            var accessibilitySettings = JSON.parse(localStorage.getItem("accessibilitySettings"));
            $.each(accessibilitySettings.bodyClassList, function (i, bodyClass) {
                $("body").addClass(bodyClass);
            });
        }
    });

    /**
     * Add an item to a localStorage() object
     * @param {String} name  The localStorage() key
     * @param {String} key   The localStorage() value object key
     * @param {String} value The localStorage() value object value
     */
    var addToLocalStorageObject = function (name, key, value) {
        var existing = JSON.parse(localStorage.getItem(name));
        existing['bodyClassList'][key] = value;
        localStorage.setItem(name, JSON.stringify(existing));
    };

    $(document).ready(function () {
        var accSettings = JSON.parse(localStorage.getItem("accessibilitySettings"));
        if (accSettings === null) {
            var settings = {
                bodyClassList: {
                    theme: "",
                    fontBold: "",
                    fontSize: "",
                    fontSimple: "",
                    focus: "",
                    cursor: "",
                    zoom: ""
                }
            };
            localStorage.setItem("accessibilitySettings", JSON.stringify(settings));
        } else {
            var settings = {
                bodyClassList: {
                    theme: accSettings.bodyClassList.theme,
                    fontBold: accSettings.bodyClassList.fontBold,
                    fontSize: accSettings.bodyClassList.fontSize,
                    fontSimple: accSettings.bodyClassList.fontSimple,
                    focus: accSettings.bodyClassList.focus,
                    cursor: accSettings.bodyClassList.cursor,
                    zoom: accSettings.bodyClassList.zoom
                }
            };
            localStorage.setItem("accessibilitySettings", JSON.stringify(settings));
        }

        var $colorTheme = $(".accessibility-options .inner #themes").find(".access li a");
        $colorTheme.on("click", function (e) {
            e.preventDefault();
            $("body").removeClass(function (index, className) {
                return (className.match(/(^|\s)acc-theme-\S+/g) || []).join(' ');
            });
            var liTheme = $(this).closest("li").data('theme');
            if (liTheme != "") {
                var themeClass = "acc-theme-" + liTheme;
                $("body").addClass(themeClass);
                addToLocalStorageObject("accessibilitySettings", "theme", themeClass);

                var icons = $("body").find(".icons a");
                icons.each(function (i, item) {
                    $(item).text($(item).attr("title"));
                });
            }
        });

        $(".accessibility-font-bold").on("click", function(){
            $("body").toggleClass("acc-font-bold");
            addToLocalStorageObject("accessibilitySettings", "fontBold", "font-bold");
        });

        $(".accessibility-focus").on("click", function(){
            $("body").toggleClass("acc-focus");
            addToLocalStorageObject("accessibilitySettings", "focus", "focus");
        });

        $(".font-size-switcher").on("click", function (e) {
            var size = $(e.target).data('size');
            if (size) {
                $("body").removeClass(function (index, className) {
                    return (className.match(/(^|\s)font-size--\S+/g) || []).join(' ');
                });
                $("body").addClass("font-size--" + size);

                $(".font-switcher__size").removeClass("active");
                $(".font-switcher__size--" + size).addClass("active");

                addToLocalStorageObject("accessibilitySettings", "fontSize", "font-size--" + size);
            }
        });

        $(".accessibility-font-simple").on("click", function() {
            $("body").toggleClass("acc-simple-font");
            addToLocalStorageObject("accessibilitySettings", "simpleFont", "acc-simple-font");
        });

        $(".accessibility-cursor-black").on("click", function() {
            if ($("body").hasClass("acc-cursor-white")) {
                $("body").removeClass("acc-cursor-white");
                $("body").addClass("acc-cursor-black");
                addToLocalStorageObject("accessibilitySettings", "cursor", "acc-cursor-black");
            } else {
                if ($("body").hasClass("acc-cursor-black")) {
                    $("body").removeClass("acc-cursor-black");
                    addToLocalStorageObject("accessibilitySettings", "cursor", "");
                } else {
                    $("body").addClass("acc-cursor-black");
                    addToLocalStorageObject("accessibilitySettings", "cursor", "acc-cursor-black");
                }
            }

        });

        $(".accessibility-cursor-white").on("click", function() {
            if ($("body").hasClass("acc-cursor-black")) {
                $("body").removeClass("acc-cursor-black");
                $("body").addClass("acc-cursor-white");
                addToLocalStorageObject("accessibilitySettings", "cursor", "acc-cursor-white");
            } else {
                if ($("body").hasClass("acc-cursor-white")) {
                    $("body").removeClass("acc-cursor-white");
                    addToLocalStorageObject("accessibilitySettings", "cursor", "");
                } else {
                    $("body").addClass("acc-cursor-white");
                    addToLocalStorageObject("accessibilitySettings", "cursor", "acc-cursor-white");
                }
            }
        });

        // $(".accessibility-zoom").on("click", function(e) {
        //     var zoom = $(e.target).data("zoom") + 25;
        //     $("body").removeClass(function (index, className) {
        //         return (className.match(/(^|\s)acc-zoom-\S+/g) || []).join(' ');
        //     });
        //     if (zoom <= 200) {
        //         $(e.target).data("zoom", zoom);
        //         $("body").addClass("acc-zoom-" + zoom);
        //         addToLocalStorageObject("accessibilitySettings", "zoom", "acc-zoom-" + zoom);
        //     } else {
        //         $(e.target).data("zoom", 100);
        //         $("body").addClass("acc-zoom-" + "100");
        //         addToLocalStorageObject("accessibilitySettings", "zoom", "acc-zoom-" + "100");
        //     }
        // });

        $(".accessibility-zoom").on("click", function(e) {
            var currentZoom = parseInt($(this).data("zoom"));
            if (!isNaN(currentZoom)) {
                var newZoom = currentZoom + 25;
                adjustZoom(newZoom);
            }
        });
        
        $(".accessibility-decrease-zoom").on("click", function(e) {
            var currentZoom = parseInt($(this).data("zoom"));
            if (!isNaN(currentZoom)) {
                var newZoom = currentZoom - 25;
                adjustZoom(newZoom);
            }
        });
        
        function adjustZoom(zoom) {
            var newZoom = zoom;
            if (newZoom < 100) {
                newZoom = 100;
            } else if (newZoom > 200) {
                newZoom = 200;
            }
        
            $("body").removeClass(function (index, className) {
                return (className.match(/(^|\s)acc-zoom-\S+/g) || []).join(' ');
            });
        
            $("body").addClass("acc-zoom-" + newZoom);
            
            $(".accessibility-zoom, .accessibility-decrease-zoom").data("zoom", newZoom); // Update data attribute for both zoom in and zoom out buttons
            addToLocalStorageObject("accessibilitySettings", "zoom", "acc-zoom-" + newZoom);
        }

      

       
      

        var $clear = $(".accessibility").find(".clearAll");
        $clear.on("click", function () {
            localStorage.clear();
            location.reload();
        });
    });
})(jQuery);
