jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchstart", handle, { passive: false });
      } else {
        this.addEventListener("touchstart", handle, { passive: true });
      }
    }
  };
  
  jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchmove", handle, { passive: false });
      } else {
        this.addEventListener("touchmove", handle, { passive: true });
      }
    }
  };


/*** Accessibility menu ***/
$('li.level1 .icon-more').on('click', function(){
  $(this).closest("li.level1").find('ul.level2').toggleClass('open-level');
  $(this).closest("li.level1").find('li.level2').toggleClass('open-level');
  $(this).closest("li.level1").find('a.level2').attr('tabindex', 0);
  
  if($(this).closest("li.level1").find('ul.level2').hasClass('open-level')) {
    $(this).closest("li.level1").find('a.level2').attr('tabindex', 0);
    $(this).closest("li.level1").find('ul.level2').attr('aria-expanded', 'true');
  } else {
    $(this).closest("li.level1").find('a.level2').attr('tabindex', -1);
    $(this).closest("li.level1").find('ul.level2').attr('aria-expanded', 'false');
  }
});

// Track focusout event on the ul.level2 container
$('ul.level2').on('focusout', function(event) {
  var $ulLevel2 = $(this);
  // Check if the next focused element is within the same ul.level2
  if (!$ulLevel2.has(event.relatedTarget).length) {
    // Focus is moving out of ul.level2, remove 'open-level' class and set tabindex to -1
    $ulLevel2.removeClass('open-level');
    $ulLevel2.find('a.level2').attr('tabindex', -1);
  };
});
// Reset tabindex for all li.level2 elements when a new one is focused within ul.level2
$('ul.level2 li.level2').on('focusin', function(event) {
  var $currentLi = $(this);
  var $ulLevel2 = $currentLi.closest('ul.level2');
  // Reset tabindex for all li.level2 elements within the same ul.level2
  $ulLevel2.find('li.level2').attr('tabindex', -1); // Set tabindex to -1 for all li.level2
  $currentLi.attr('tabindex', -1); // Set tabindex to 0 for the focused li.level2
});

$('.mobile-english').on('focusout', function(){
  if ($(window).width() < 991) {
    $("#hamburger").removeClass("is-active");
    $(".header-container").removeClass("navi-open");
    $("body").removeClass("navi-open");
    $(".navigation-wrapper").removeClass("navi-open");


    $("a.level1").attr('tabindex', -1);
    $(".accessibility-toggle").attr('tabindex', -1);
    $(".mobile-english").attr('tabindex', -1);
    $(".icon-more").attr('tabindex', -1);
  }
});

if ($(window).width() < 991) {
  $("a.level1").attr('tabindex', -1);
  $("li.level1").attr('tabindex', -1);
  $(".accessibility-toggle").attr('tabindex', -1);
  $(".mobile-english").attr('tabindex', -1);
  $(".icon-more").attr('tabindex', -1);
  $(".skip-to-content").attr('tabindex', -1);
} else {
  $("a.level1").attr('tabindex', 0);
  //$("li.level1").attr('tabindex', 0);
  $(".accessibility-toggle").attr('tabindex', 0);
  $(".mobile-english").attr('tabindex', 0);
  $(".icon-more").attr('tabindex', 0);
  $(".skip-to-content").attr('tabindex', 0);
}

function goToMainContent() {
  $("html, body").animate({ scrollTop: $("#main").offset().top }, 1000);
  $("#main").attr("tabindex", -1).focus();
}

$(".skip-to-content").on('click', function() {
  if ($(window).width() < 991) {
    $("#hamburger").removeClass("is-active");
  $(".header-container").removeClass("navi-open");
  $("body").removeClass("navi-open");
  $(".navigation-wrapper").removeClass("navi-open");
  $("ul.level2").removeClass("open-level");
  }
})

/*** Accessibility menu end ***/

/**** Main menu ****/
$("#hamburger").on('click', function () {
  $("#hamburger").toggleClass("is-active");
  $(".header-container").toggleClass("navi-open");
  $("body").toggleClass("navi-open");
  $(".navigation-wrapper").toggleClass("navi-open");
  $("ul.level2").removeClass("open-level");

  if($(this).hasClass('is-active')) {
    $(this).attr('aria-expanded', 'true');
  } else {
    $(this).attr('aria-expanded', 'false');
  }

  if ($(window).width() < 991) {
    if($(".navigation-wrapper").hasClass("navi-open")) {
      $("a.level1").attr('tabindex', 0);
      $(".accessibility-toggle").attr('tabindex', 0);
      $(".mobile-english").attr('tabindex', 0);
      $(".icon-more").attr('tabindex', 0);
    } else {
      $("a.level1").attr('tabindex', -1);
      $(".accessibility-toggle").attr('tabindex', -1);
      $(".mobile-english").attr('tabindex', -1);
      $(".icon-more").attr('tabindex', -1);
    }
  }
 
});

$(".show-more").on('click', function(){
  $(this).closest('li.level1').find('ul.level2').toggle();
  $(this).closest('li.level1').toggleClass('open');
});

$(".back").on('click', function(){
  $(this).closest("ul.level2").removeClass('open-level');
});
/**** Main menu end ****/

if ($('.stories-slider').length > 0) {
  document.addEventListener('DOMContentLoaded', function () {
    var splide = new Splide('.stories-slider', {
      rewind: true,
      arrows: true,
      pagination: false,
      perPage: 1,
      drag: true,
      type: 'fade',
      autoplay: true,
      speed: 2000
    }).mount();
  });
}

$('a[href^="#"]').click(function(e) {
  e.preventDefault();
  
  var targetId = $(this).attr('href').substring(1);
  var targetElement = $('#' + targetId);
  
  if (targetElement.length) {
      var offsetTop = targetElement.offset().top;
      $('html, body').animate({
          scrollTop: offsetTop
      }, 'slow');
  }
});
